/*--
    02. Header
-----------------------------------------*/

.header-top {
    background: #444444;
    padding: 10px 0px;

    & p {
        color: $white;
    }
}

.header-bottom-area {
    background-color: $color-dark-red;
    background: linear-gradient(72deg, #A00300 0%, #A00300 71%, #1C1C1C 50%, #1C1C1C 100%);

    // Responsive
    @media only screen and (max-width: 1333px) and (min-width: 992px) {
        background: linear-gradient(72deg, #A00300 0%, #A00300 73%, #1C1C1C 50%, #1C1C1C 100%);
    }

    // Responsive
    @media only screen and (max-width: 1215px) and (min-width: 992px) {
        background: linear-gradient(72deg, #A00300 0%, #A00300 75%, #1C1C1C 50%, #1C1C1C 100%);
    }

    @media only screen and (max-width: 1200px) and (min-width: 992px) {
        background: linear-gradient(72deg, #A00300 0%, #A00300 80%, #1C1C1C 50%, #1C1C1C 100%);
    }

    @media #{$large-mobile} {
        & p {
            text-align: center;
        }
    }
}

.logo {
    @media #{$large-mobile,$tablet-device} {
        margin: 20px 0;
    }
}

.social-top {
    text-align: right;

    & li {
        display: inline-block;
        margin-left: 25px;

        &:first-child {
            margin-left: 0px;
        }

        & a {
            font-size: 13px;
            color: #fff;

            &:hover {
                color: $theme-color;
            }
        }
    }

    @media #{$large-mobile , $tablet-device} {
        text-align: center;
        margin-bottom: 10px;
    }
}

.mobile-more-info {
    text-align: center;

    i {
        font-size: 28px;
    }
}

/*--
    02. 1 Menu Css
---------------------*/
.main-menu {

    &>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-family: $heading-font;

        &>li {
            position: relative;
            display: inline-block;
            margin: 0 15px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &>a {
                display: block;
                color: #333;
                padding: 35px 0;
                font-weight: 500;

                &:hover {
                    color: $theme-color;
                }

                &.active {
                    border-bottom: 2px solid white;
                }
            }

            &:hover {
                &>a {
                    color: $theme-color;
                }

                &>.sub-menu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                    z-index: 999;
                }
            }
        }

        @media #{$laptop-device} {
            & li {
                margin: 0 15px;
            }
        }

        @media #{$desktop-device} {
            & li {
                margin: 0 8px;
            }
        }

    }

    &.menu-color-black {
        &>ul {
            &>li {
                &>a {
                    display: block;
                    color: #222;

                    &:hover {
                        color: $theme-color;
                    }
                }

                &:hover {
                    &>a {
                        color: $theme-color;
                    }

                    &>.sub-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                        z-index: 999;
                    }
                }

                @media #{$laptop-device} {
                    & li {
                        margin: 0 15px;
                    }
                }

                @media #{$desktop-device} {
                    & li {
                        margin: 0 8px;
                    }
                }
            }
        }
    }

    &.light {
        >ul>li>a {
            color: white;

            &:hover {
                opacity: .5;
            }
        }
    }
}


/*-- Sub Menu --*/
.sub-menu {
    position: absolute;
    left: 0px;
    top: 100%;
    background-color: $black;
    box-shadow: 0 10px 15px rgba($black, 0.3);
    border-bottom: 3px solid $theme-color;
    z-index: -999;
    width: 200px;
    padding: 15px 0;
    margin-top: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease 0s;

    & li {
        position: relative;
        text-align: left;

        &>a {
            line-height: 24px;
            padding: 6px 20px;
            display: block;
            font-weight: 500;
            color: $white;
            text-transform: capitalize;

            &:hover {
                color: $theme-color;
            }
        }

        &.menu-item-has-children {
            &>a {
                &::after {
                    font-family: 'themify';
                    content: "\e649";
                    margin-left: 5px;
                    float: right;
                    font-size: 10px;
                }
            }
        }

        // Hover & Active
        &.active,
        &:hover {
            &>a {
                color: $theme-color;
                font-weight: 500;
            }
        }

        &:hover {
            &>.sub-menu {
                opacity: 1;
                visibility: visible;
                margin-top: 0;
                z-index: 999;
            }
        }
    }

    & .sub-menu {
        left: 100%;
        right: auto;
        top: 0;

        & .sub-menu {
            left: auto;
            right: 100%;

            & .sub-menu {
                left: 100%;
                right: auto;
            }

            & .sub-menu {
                left: auto;
                right: 100%;
            }
        }
    }

    @media #{$laptop-device} {
        & li {
            & a {
                padding: 6px 0px;
            }
        }
    }
}

/*--
    02. 2 Mobile Menu Css
----------------------*/

.slinky-theme-default {
    background: transparent;
    min-height: 310px;
}

.slinky-theme-default a:not(.back):hover {
    background: rgba(255, 255, 255, 0.1);
}

.slinky-theme-default a:not(.back) {
    color: white;
}

.clickable-mainmenu {
    background: #1B1B1B;
    color: white;
    height: 100vh;
    overflow-y: auto;
    padding: 40px 10px 40px;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    transform: translateX(-110%);
    -webkit-transition: -webkit-transform 0.5s ease-in-out 0s;
    transition: -webkit-transform 0.5s ease-in-out 0s;
    -o-transition: transform 0.5s ease-in-out 0s;
    transition: transform 0.5s ease-in-out 0s;
    transition: transform 0.5s ease-in-out 0s, -webkit-transform 0.5s ease-in-out 0s;
    width: 300px;
    z-index: 9999;

    h4 {
        color: white;
    }
}

.clickable-mainmenu.inside {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    z-index: 9999;
}

.clickable-menu.clickable-mainmenu-active a {
    float: right;
    font-size: 30px;
    color: white;
}

.clickable-menu.clickable-mainmenu-active.bg-white-icon a {
    color: white;
}

.clickable-mainmenu-close {
    background: transparent;
    text-align: right;
    border: none;
    font-size: 20px;
    margin-bottom: 30px;
    margin-left: 8px;
}

.header-top-info-area {
    padding: 15px 0 5px;
}

.wlc-text {
    color: #fff;
    margin-bottom: 10px;
}

.social-share {
    margin-bottom: 10px;
    color: #fff;
    text-align: center;

    & .social-info {
        & li {
            display: inline-block;
            margin: 0 10px;
        }
    }
}

.header-phone {
    text-align: right;
    color: #fff;
    margin-bottom: 10px;
}

.header-top-area.white-bg {
    & .listing-btn {
        & .btn-2 {
            color: #222;

            &:hover {
                color: #fff;
            }
        }
    }
}

.details a {
    color: white;
    font-size: 15px;
    margin-right: 20px;

    // Responsive
    @media only screen and (max-width: 1200px) and (min-width: 992px) {
        display: none;
    }

    &:first-child {
        margin-right: 31px;
    }

    &:hover {
        opacity: .5;
    }

    >i {
        font-size: 24px;

        // Responsive
        @media only screen and (max-width: 1215px) and (min-width: 992px) {
            display: none;
        }
    }
}