/*--
    05. Store Css
--------------------------*/
.store {
    .bg-image {
        background-image: url('/assets/images/store/store-banner.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}