/*--
    03. Hero
-----------------------------------------*/
.hero-section {
    position: relative;
    background: black;
}


/*-- Hero Slider --*/
.hero-slider-one {

    .slick-arrow {
        background: darken($heading-color, 5%);
        border: none;
        padding: 20px;
        opacity: .75;
        z-index: 1;

        &:hover {
            opacity: 1;
        }

        >i {
            color: white;
            font-size: 25px;
            -webkit-font-smoothing: antialiased;
        }

        // RESPONSIVE
        @media #{$small-mobile} {
            display: none !important;
        }

    }

    .homeHero-next,
    .homeHero-prev {
        position: absolute;
        top: 45%;
    }

    .homeHero-next {
        right: 0;
    }


    .slick-dots {
        text-align: center;
        height: 2px;
        position: relative;
        top: -80px;

        li {
            position: relative;
            display: inline-block;
            margin: 0 8px;
            padding: 0;
            cursor: pointer;

            button {
                width: 25px;
                height: 2px;
                padding: 2px;
                background: white;
                border: none;
                text-indent: -9999px;

                &::before {
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background: $theme-color-2;
                }
            }
        }
    }
}

/*-- Hero Content --*/
.hero-content {
    @media #{$large-mobile} {
        order: 2;
        margin-top: 30px;
    }

    // All Element Selector
    &>* {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }

    & h6 {
        color: $theme-color-2;
    }

    & h1 {
        font-size: 62px;
        font-weight: 700;
        margin-bottom: 35px;
        color: $white;

        // Responsive
        @media #{$desktop-device} {
            font-size: 46px;
        }

        @media #{$tablet-device} {
            font-size: 46px;
        }

        @media #{$large-mobile} {
            font-size: 32px;
            line-height: 40px;

            & br {
                display: none;
            }
        }

        @media #{$small-mobile} {}
    }

    & p {
        max-width: 930px;
        color: $white;
        margin: 0 auto 20px;
        font-size: 18px;
        line-height: 28px;
    }

    & a {
        // height: 48px;
        line-height: 26px;
        padding: 10px 40px;
        border: 1px solid $theme-color;
        background-color: $theme-color;
        color: $white;
        // border-radius: 50px;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        margin-top: 25px;

        &:hover {
            background-color: $heading-color;
            border-color: $heading-color;
            color: $white;
        }

        // Responsive
        @media #{$large-mobile} {
            margin-top: 10px;
            padding: 8px 25px;
            line-height: 22px;
            height: 40px;
        }

        @media #{$small-mobile} {}
    }
}

/*-- Slide Content In Animation --*/
.slick-active {

    /*-- Hero Content --*/
    & .hero-content {

        // All Element Selector
        &>* {
            animation-name: fadeInUp;

            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }

            &:nth-child(2) {
                animation-delay: 1s;
            }

            &:nth-child(3) {
                animation-delay: 1.5s;
            }

            &:nth-child(4) {
                animation-delay: 2s;
            }

            &:nth-child(5) {
                animation-delay: 2.5s;
            }

            &:nth-child(6) {
                animation-delay: 3s;
            }

        }
    }

}