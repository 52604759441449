// Font Family
$body-font: 'Poppins',
sans-serif;
$heading-font: 'Montserrat',
sans-serif;


// Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;

// Body Color
$body-color: #454545;

// Heading Color
$heading-color: #1D1D1D;

// Theme Color
$theme-color: #C80211;
$theme-color-2: #FFC801;
$color-dark-red: #A00300;



// Responsive Variables
$extraBig-device : 'only screen and (min-width: 1600px)';
$laptop-device : 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$desktop-device : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$tablet-device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large-mobile:'only screen and (max-width: 767px)';
$small-mobile: 'only screen and (max-width: 479px)';