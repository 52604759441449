/*--
    04. Service Css
-------------------------------------*/

.service-2 {
  background: #ffffff;
  padding: 30px;
  transition: 0.4s ease-in-out;

  &.padding-none {
    padding: 0;
  }

  .thumb {
    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  &:hover .thumb a img {
    transform: scale(1.1) rotate(5deg);
  }

  &.cleaning-service {
    .content {
      padding: 30px 20px;

      & h4 {
        font-weight: 600;
      }

      p {
        margin-bottom: 8px;
      }

      & .readmore_btn {
        font-weight: 600;
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  .content {
    padding-top: 30px;

    h4 {
      color: #575757;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 13px;
      padding: 0 9px;
    }

    p {
      color: #818181;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 35px;
    }

    a {
      &.readmore_btn {
        height: 42px;
        border: 1px solid #0056ff;
        display: inline-block;
        padding: 0 42px;
        line-height: 40px;
        font-size: 14px;
        border-radius: 30px;
        color: #0056ff;
        font-weight: 600;
        transition: 0.5s;

        &:hover {
          background: #0056ff;
          color: #fff;
        }

        &.btn-transparent {
          border: 0 none;
          width: inherit;
          height: inherit;
          color: #3f3f3f;
          font-size: 14px;
          font-family: $heading-font;
          font-weight: 600;

          &:hover {
            color: $theme-color;
            background: transparent;
          }
        }
      }
    }
  }

  &.agency-service {
    background: #f4f4f4;

    .content {
      padding-top: 30px;

      a {
        &.readmore_btn {
          border: 1px solid $theme-color;
          color: $theme-color;

          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }
    }

    &:hover {
      background: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.repair-service {
    background: #ffffff;

    .content {
      padding-top: 30px;

      a {
        &.readmore_btn {
          border: 2px solid $theme-color;
          color: $theme-color;
          line-height: 38px;

          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.carpenter-service {
    background: #ffffff;

    .content {
      padding: 30px;

      a {
        &.readmore_btn {
          border: 2px solid $theme-color;
          color: $theme-color;
          line-height: 38px;

          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.popular-courses {
    background: #f4f4f4;

    .content {
      padding-top: 30px;

      h4 {
        padding: 0;
      }

      p {
        margin-bottom: 26px;
      }

      a {
        &.readmore_btn {
          border: 1px solid $theme-color;
          color: $theme-color;

          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }

      ul {
        padding: 0;
        display: flex;
        margin: 0;
        list-style: none;

        &.crs-meta {
          margin-bottom: 17px;

          li {
            color: #6e6e6e;
            font-size: 14px;
            font-family: $heading-font;
            margin-right: 14px;

            a {
              color: #6e6e6e;
            }
          }
        }

        &.crs-price {
          justify-content: space-between;

          li {
            color: #6e6e6e;
            font-size: 14px;
            font-family: $heading-font;
            margin-right: 14px;

            i {
              padding-right: 7px;
            }

            a {
              color: #6e6e6e;
            }

            &.price {
              color: $theme-color;
            }
          }
        }
      }
    }

    &:hover {
      background: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.body-fit-choose {
    background: #ffffff;

    .content {
      padding: 40px;

      i {
        font-size: 69px;
        margin-bottom: 11px;
        display: inline-block;
        color: #404040;
      }

      h4 {
        padding: 0;
        font-size: 22px;
        font-weight: 500;
      }

      p {
        margin-bottom: 26px;
        color: #4d4d4d;
        line-height: 27px;
      }

      a {
        &.readmore_btn {
          border: 1px solid transparent;
          padding: 0;
          border-radius: inherit;
          color: #404040;

          &:hover {
            background: transparent;
            color: $theme-color;
          }
        }
      }
    }

    &:hover {
      background: #fb5b21;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .content {
        i {
          color: #ffffff;
        }

        h4 {
          color: #ffffff;
        }

        p {
          color: #ffffff;
        }

        a {
          &.readmore_btn {
            color: #fff;
          }
        }
      }
    }
  }

  &.beauty-service {
    background: #ffffff;

    .content {
      padding: 40px;

      h4 {
        padding: 0;
        font-size: 22px;
        font-weight: 500;
      }

      p {
        margin-bottom: 13px;
        color: #5c5c5c;
        line-height: 27px;
      }

      a {
        &.readmore_btn {
          border: 1px solid transparent;
          padding: 0;
          border-radius: inherit;
          color: #404040;
          height: auto;
          line-height: initial;

          &:hover {
            background: transparent;
            color: $theme-color;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .content {
        a {
          &.readmore_btn {
            color: #ff759d;
          }
        }
      }
    }
  }
}

/* Cleaning Service Activation  */

.cln-service-activation {
  overflow: hidden;

  & button {
    position: absolute;
    top: 50%;
    left: 0px;
    color: #f4f4f4;
    border: 0 none;
    font-size: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #000000;
    transform: translateY(-50%);
    z-index: 2;
    border-radius: 100%;
    transition: 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &:hover {
      color: #ffffff;
      background: $theme-color;
    }

    &.clnService-next {
      left: auto;
      right: 0px;
    }
  }

  &:hover {
    & button {
      opacity: 1;
      visibility: visible;
    }
  }
}

/*--
    04.1 Service Style two
----------------------------------*/
.service-6 {
  margin-top: 12px;

  .icons {
    border: 1px solid #4e89fb;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 100%;
    float: left;
    text-align: center;
    margin-right: 22px;
    margin-top: 10px;

    @media #{$large-mobile} {
      margin: auto;
      margin-bottom: 21px;
      float: inherit;
    }
  }

  .content {
    overflow: hidden;

    p {
      font-size: 14px;
      line-height: 25px;
      color: #5b5b5b;
    }

    h4 {
      color: #5f5f5f;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 7px;

      @media #{$large-mobile} {
        font-size: 21px;
      }
    }
  }

  &.reapir-service {
    margin-top: 12px;

    .icons {
      border: 0 none;
      min-width: 71px;
      width: auto;
      height: auto;
      line-height: inherit;
      border-radius: inherit;
      float: left;
      text-align: center;
      margin-right: 22px;
      margin-top: 10px;
    }

    .content {
      p {
        font-size: 16px;
        line-height: 30px;
        color: #585757;
        max-width: 390px;
      }

      h4 {
        color: #3d3d3d;
      }
    }
  }

  &.medical-service {
    background: #f7f7f7;
    margin-top: 0;
    padding: 50px 69px;

    &.service-bg-2 {
      background: #ededed;
    }

    .icons {
      border: 1px solid transparent;
      width: auto;
      height: auto;
      line-height: inherit;
      border-radius: inherit;
      float: left;
      text-align: center;
      margin-right: 25px;
      margin-top: 10px;
      max-width: 60px;
    }

    .content {
      overflow: hidden;

      h4 {
        color: $heading-font;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 7px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        line-height: 26px;
        color: $heading-font;
      }
    }

    @media #{$laptop-device} {
      padding: 30px 20px;
    }

    @media #{$desktop-device} {
      padding: 30px 20px;

      & .icons {
        max-width: 45px;
        margin-right: 15px;
      }

      & .content {
        h4 {
          font-size: 16px;
        }
      }
    }

    @media #{$tablet-device} {
      padding: 25px 25px;
    }

    @media #{$large-mobile} {
      padding: 29px 15px;
    }

    @media #{$small-mobile} {
      padding: 39px 15px;
    }
  }

  .section-service {
    @media #{$tablet-device} {
      & .col-12 {
        &:nth-child(odd) {
          & .service-6.medical-service {
            padding-top: 0px;
          }
        }
      }
    }
  }

  &.bd-service {
    .icons {
      border: 1px solid transparent;
      width: auto;
      height: 50px;
      line-height: 50px;
      border-radius: inherit;
      float: left;
      text-align: center;
      margin-right: 24px;
      margin-top: 10px;
      padding-right: 17px;
      border-right: 1px solid #5e5e5e;

      i {
        color: #5e5e5e;
        font-size: 53px;
        transition: 0.4s ease-in-out;

        &:hover {
          color: #fb5b21;
        }
      }

      @media #{$large-mobile} {
        margin: auto;
        margin-bottom: 21px;
        float: inherit;
      }
    }

    .content {
      p {
        font-size: 14px;
        line-height: 25px;
        color: #5b5b5b;
      }

      h4 {
        color: #565656;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 7px;

        @media #{$large-mobile} {
          font-size: 21px;
        }
      }
    }
  }
}

.boxed-layout {
  & .medical-service {
    padding: 50px 30px;

    @media #{$laptop-device} {
      padding: 30px 20px;
    }

    @media #{$desktop-device} {
      padding: 30px 20px;

      & .icons {
        max-width: 45px;
        margin-right: 15px;
      }

      & .content {
        h4 {
          font-size: 16px;
        }
      }
    }

    @media #{$tablet-device} {
      padding: 25px 25px;
    }

    @media #{$large-mobile} {
      padding: 29px 15px;
    }

    @media #{$small-mobile} {
      padding: 39px 15px;
    }
  }
}

.single-service {
  & img {
    width: 100%;
  }

  &:hover {
    & .service-content {
      box-shadow: 0 4px 18px 0 rgba(85, 85, 85, 0.2);
    }
  }
}

.service-content {
  background: $white;
  padding: 30px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  & h3 {
    font-size: 22px;
    font-weight: 600;
  }

  & p {
    font-size: 16px;
    margin: 10px 0 10px 0;
  }

  & > a {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $theme-color;

    &:hover {
      color: #333;
    }
  }
}

.service-slider-active {
  & .slick-arrow {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    background: #ddd;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;

    &:hover {
      background: $theme-color;
      color: $white;
    }

    &.slick-next {
      right: 0;
      left: auto;
    }
  }

  &:hover .slick-arrow {
    transform: translateY(-50%);
    visibility: visible;
    opacity: 1;
  }
}

.service-area-tow {
  background: $theme-color;
  position: relative;
  padding-left: 20px;

  &::after {
    background: $theme-color;
    position: absolute;
    content: "";
    left: 0;
    top: -19px;
    height: 20px;
    width: 80%;
    z-index: 9;
  }

  &::before {
    background: $theme-color;
    position: absolute;
    content: "";
    left: 0;
    bottom: -19px;
    height: 20px;
    width: 80%;
    z-index: 9;
  }

  @media #{$large-mobile} {
    padding-left: 0px;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  & .single-service {
    background: #f7f7f7;
    border-top: 1px solid #d0d5da;
    border-right: 1px solid #d0d5da;
    border-bottom: 1px solid #d0d5da;
    padding: 50px 30px;
    position: relative;

    &:hover {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    & .service-icon {
      margin-bottom: 15px;

      & i {
        font-size: 38px;
      }
    }

    & .service-content-info {
      & h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        font-weight: 600;
      }

      & p {
        font-size: 14px;
      }
    }
  }

  & .col-lg-4 {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      & .single-service {
        border-top: none;
      }
    }

    @media #{$tablet-device} {
      &:nth-child(4) {
        & .single-service {
          border-top: 1px solid #d0d5da;
        }
      }
    }

    @media #{$large-mobile} {
      &:nth-child(3) {
        & .single-service {
          border-top: none;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        & .single-service {
          border-left: 1px solid #d0d5da;
        }
      }
    }
  }

  & .single-service-title-area {
    height: 250px;

    & .service-title {
      font-weight: 600;
      transform: translateY(50%);
      padding: 0 25px 0 50px;

      @media #{$small-mobile} {
        padding: 0 25px 0 0px;
      }

      @media #{$large-mobile} {
        padding-left: 20px;
        transform: translateY(50%);
      }

      @media #{$small-mobile} {
        padding-left: 20px;
        transform: translateY(30%);
      }

      & h2 {
        font-size: 22px;
        color: $white;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 20px;
        font-weight: 700;
        letter-spacing: 1px;

        &::after {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 35px;
          height: 1px;
          background: #fff;
        }
      }

      & p {
        color: #fff;
      }
    }
  }
}

.service-details-menu {
  & li {
    & a {
      background: #f1f1f1;
      font-size: 16px;
      font-weight: 500;
      display: block;
      padding: 20px;
      text-align: center;

      &.active {
        background: $theme-color;
        color: $white;
      }
    }

    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.service-details-content {
  & h4 {
    font-weight: 600;
    margin-bottom: 15px;
  }

  @media #{$large-mobile , $tablet-device} {
    margin-top: 30px;
  }

  & .service-details-image {
    @media #{$large-mobile , $tablet-device} {
      margin-top: 30px;
    }
  }
}

.service-details-content-inner {
  & h4 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  & .service-details-list {
    margin-top: 30px;

    & li {
      margin-bottom: 10px;

      &:last-child {
        margin: 0;
      }

      & i {
        color: $theme-color;
        margin-right: 10px;
      }
    }
  }
}

.row-service-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background: #ededed;
}

.home-section-service {
  .title-4:before {
    left: 50%;
  }

  h2 {
    color: white;
  }

  p {
    color: #a8a8a8;
  }

  .service-items-list {
    margin-top: 100px;
  }

  .service-item {
    margin-bottom: 60px;

    .service-item-icon {
      background-color: lighten($heading-color, 8%);
      border-radius: 100%;
      padding: 0;
      width: 75px;
      height: 75px;
      text-align: center;
      flex-shrink: 0;

      > i {
        font-size: 3.1em;
        color: $theme-color-2;
        -webkit-font-smoothing: antialiased;
        line-height: 76px;

        &:before {
          margin: 0;
          line-height: 70px;
        }
      }
    }

    .service-item-detail {
      text-align: left;
      padding: 10px 0 0 0;
      margin-left: 1.8em;

      h6 {
        color: white;
        font-size: 16px;
        line-height: 26px;
      }

      p {
        font-size: 14px;
      }
    }

    .icon-disc-brake {
      margin-left: 5px;
    }
  }
}

.services {
  .title-4:before {
    left: 50%;
  }

  .bg-image {
    background-image: url("/assets/images/serviece/brake-job.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .service-details-content ul li {
    font-size: 16px;
    line-height: 36px;
    list-style-type: disc;
    list-style-position: inside;
  }

  .full-list-services-container {
    background-image: url(/assets/images/serviece/tire-tracks.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
  }

  .full-list-services {
    .list-group {
      margin: 10px 0;
    }

    // .list-group-item {
    //     background-color: $heading-color;
    //     color: white;
    // }
  }
}
