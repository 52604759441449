/*--
    12. Footer Css
----------------------*/
.footer-top {
    // background: #f4f4f4;
}

.footer-widget {
    &.footer-style-1 {
        margin-top: 30px;

        &.space-left {
            position: relative;
            margin-left: 54px;

            @media #{$large-mobile,$tablet-device} {
                margin-left: 0px;
            }
        }

        h5 {
            &.ft-title {
                font-weight: 600;
                font-size: 18px;
                margin-top: -5px;
                color: $white;

                & span {
                    color: $theme-color;
                }
            }
        }

        .content {
            margin-top: 30px;

            @media #{$large-mobile,$tablet-device} {
                margin-top: 15px;
            }

            p {
                color: #A8A8A8;
                font-size: 14px;
                line-height: 25px;
                margin: 0;
            }

            ul {
                &.ft-menu {
                    padding: 0;
                    list-style: none;


                    li {
                        color: #A8A8A8;
                        margin-bottom: 15px;
                        line-height: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            color: #A8A8A8;
                            font-weight: 400;
                            font-size: 13px;
                            transition: 0.4s ease-in-out;

                            &:hover {
                                color: $theme-color;
                            }
                        }

                        &.telephone,
                        &.address,
                        &.email {
                            margin-left: 25px;

                            &:before {
                                font-family: "fontello";
                                content: "\e851";
                                position: absolute;
                                left: 12px;
                                font-size: 18px;
                                margin: 0 auto;
                                text-align: center;
                            }
                        }

                        &.address:before {
                            content: "\e833";
                            font-size: 24px;
                        }

                        &.telephone:before {
                            content: "\e81a";
                        }
                    }

                    &.hours {
                        span {
                            min-width: 110px;
                            display: inline-block;

                        }
                    }

                    &.about li {
                        margin-bottom: 25px;
                    }
                }
            }

            .ft-address {
                margin-top: 14px;

                p {
                    margin-bottom: 4px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .input-box {
                position: relative;
                margin-bottom: 15px;
                margin-top: 17px;

                input {
                    background-color: #2E2E2E;
                    width: 100%;
                    height: 45px;
                    border: 1px solid #2E2E2E;
                    padding: 0 50px 0 10px;
                    color: white;
                }

                button {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border: 0 none;
                    border-left: 1px solid rgba(255, 255, 255, 0.1);
                    background: transparent;
                    height: 100%;
                    color: #fff;
                    padding: 0 13px;
                    transition: 0.4s ease-in-out;

                    &:hover {
                        background: $theme-color;
                        color: #ffffff;
                    }
                }

            }

            .social-share {
                &.social-square {
                    a {
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        font-size: 13px;
                    }
                }
            }
        }

        @media #{$small-mobile} {
            margin: 30px 0;
        }

    }

    .social-share.social-square {
        text-align: left;
        margin-bottom: 0;

        & a {
            color: #A8A8A8;
            text-align: center;

            &:hover {
                background: $theme-color;
                color: $white;
            }
        }

    }
}

.footer-widget.footer-style-1 .content .input-box input::-webkit-input-placeholder {
    color: #ffffff;
}

.footer-widget.footer-style-1 .content .input-box input::-moz-placeholder {
    color: #ffffff;
}

.footer-widget.footer-style-1 .content .input-box input:-ms-input-placeholder {
    color: #ffffff;
}

.footer-widget.footer-style-1 .content .input-box input:-moz-placeholder {
    color: #ffffff;
}

.ht-footer-1 {
    padding: 100px 0;
    padding-top: 70px;
}

.ht-footer-2 {
    padding: 85px 0;
    padding-top: 52px;
    padding-bottom: 83px;
}

/* CopyiRight Style */
.copyright {
    background: #131212;
    padding: 12px 0;

    .content {
        p {
            color: #424242;
            font-size: 14px;
            margin: 0;
        }
    }

    .social-fb {
        font-size: 18px;

        &:before {
            font-family: "Font Awesome 5 Brands";
            content: "\f09a";
            position: relative;
            top: 2px;
        }

    }
}