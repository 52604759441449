/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header
        02. 1 Menu Css
        02. 2 Mobile Menu Css
    03. Hero
    04. Service Css
        04.1 Service Style two
    05. About Css
    06. Contact Css 
    07. Team Style Css
    08. Blog Css
    09. Blog Details Css
    10. Testimonial Css
    11. Newsletter Css
    12. Footer Css
    
    
	
    

-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'hero';
@import 'service';
@import 'about';
@import 'contact';
@import 'store';
@import 'team';
@import 'blog';
@import 'blog-details';
@import 'testimonial';
@import 'newsletter';
@import 'footer';