/*---
   06. Contact Css 
----------------------------------------*/

.contact_form_container {
    padding: 65px 60px;
    &.repair-request-form{
        background: #f4f4f4;
        .ct-title-2{
            text-align: center;
            h2 {
                font-weight: 600;
                color: #3d3d3d;
                font-size: 30px;
                @media #{$large-mobile}{
                    font-size: 24px;
                }
            }
        }
    }
    @media #{$large-mobile}{
        padding: 30px;
        margin-top: 30px;
    }
    @media #{$tablet-device}{
        margin-top: 30px
    }
}

.repair-service-form{
    form {
        input {
            margin-top: 30px;
            height: 50px;
            line-height:  50px;
            color:  #fff;
            font-weight: 300;
            padding: 0 20px;
            width: 100%;
            font-family: $heading-font;
            border:  1px solid #e1e1e1;
            background: #ffffff;
            color: #707070;
            &:focus{
                border: 1px solid $theme-color;
            }

            &[type="submit"] {
                background:  #fff;
                height: 55px;
                line-height: 56px;
                border:  0 none;
                padding: 0 30px;
                color:  #0056ff;
                font-weight: 600;
                width:  auto;
                transition: 0.5s;
                color:  #ffffff;
                width:  100%;
                background: $theme-color;
                font-size: 14px;
                letter-spacing: 1px;
                text-transform: uppercase;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        textarea {
            margin-top:  30px;
            min-height:  145px;
            width: 100%;
            border:  1px solid #fff;
            padding:  20px;
            font-weight:  300;
            color:  #fff;
            font-family: $heading-font;
                border:  1px solid #e1e1e1;
                color:  #707070;
                background: #ffffff;
                &:focus{
                    border: 1px solid $theme-color;
                }
        }
    }
}

.repair-service-form form input::-webkit-input-placeholder {
    color: #444;
}
.repair-service-form form input::-moz-placeholder {
    color: #444;
}
.repair-service-form form input:-ms-input-placeholder {
    color: #444;
}
.repair-service-form form input:-moz-placeholder {
    color: #444;
}
.repair-service-form form textarea::-webkit-input-placeholder {
    color: #444;
}
.repair-service-form form textarea::-moz-placeholder {
    color: #444;
}
.repair-service-form form textarea:-ms-input-placeholder {
    color: #444;
}
.repair-service-form form textarea:-moz-placeholder {
    color: #444;
}

/*--
    - Contact Us Css
-----------------------------------*/


#googleMap-2 {
    height: 600px;
    width: 100%;
    @media #{$large-mobile}{
        height: 400px;
    }
}
.form_wrapper {
    padding: 55px 70px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: -107px;
    background: #fff;
    
    @media #{$large-mobile,$tablet-device}{
        padding: 30px;
    }
    
}

.form-inner-box-warp {
    & input,textarea {
        width: 100%;
        border: 1px solid #c1c1c1;
        padding: 8px 15px;
        color: #c1c1c1;
    }
    & textarea {
        height: 170px;
    }
    & .submit-btn {
        background: #f9b809;
        color: #ffffff;
        border: none;
        padding: 8px 40px;
        text-transform: uppercase;
        font-weight: 500;
        &:hover {
            background: $heading-color;
        }
    }
}
.contact-info-wrapper {
    @media #{$large-mobile}{
        margin-top: 55px;
        margin-bottom: 60px;
    }
}
.contact-info {
    & ul {
        & li {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.contact-text {
    & i {
        font-size: 20px;
        color: $theme-color;
        border: 1px solid $theme-color;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 15px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $theme-color;
            color: $white;
        }
    }
    & p {
        & a {
            display: block;
        }
    }
}