/*--
    09. Blog Details Css
----------------------------*/
.meta-body {
    ul {
        & li {
            margin-right: 10px;
        }
    }
}
.postinfo-wrapper {
    & h3 {
        font-weight: 600;
    }
}
.blog-date-categori {
    margin-bottom: 10px;
    & ul {
        & li {
            display: inline-block;
            & a {
                font-size: 14px;
                margin-right: 10px;
                font-weight: 500;
                & i {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.search-post {
    position: relative;
    margin-top: 20px;
    & input {
        border: 1px solid #dddddd;
        font-size: 14px;
        height: 40px;
        padding: 10px 50px 10px 10px;
        position: relative;
        width: 100%;
    }
    & .btn-search {
        border: medium none;
        font-size: 16px;
        padding: 7px 15px;
        position: absolute;
        right: 0;
        top: 0;
        background: #ddd;
        &:hover {
            background: $theme-color;
            color: $white;
        }
    }
}
.social-icons {
    float: right;
    margin-top: 5px;
    & li {
        display: inline-block;
        margin-left: 10px;
    }
}
.blog-image-slider {
    & .slick-arrow {
        background: #dddddd none repeat scroll 0 0;
        border: 1px solid #dddddd;
        font-size: 16px;
        height: 40px;
        position: absolute;
        top: 50%;
        width: 40px;
        z-index: 9;
        transform: translateY(-50%);
        &:hover {
            background: $theme-color;
            color: $white;
            border-color: $theme-color;
        }
    }
    & .slick-next {
        right: 0;
    }
}
.post-info {
    margin-top: 30px;
    & .blockquote-inner {
        border-left: 5px solid $theme-color;
        background: #fafafa;
        font-size: 16px;
        font-style: italic;
        margin: 30px 0;
        padding:  20px 10px;
    }
    & .post-commet {
        border-bottom: 1px solid #e7e4dd;
        border-top: 1px solid #e7e4dd;
        font-size: 14px;
        margin: 60px 0 30px;
        padding: 15px 0;
        text-align: left;
        text-transform: uppercase;
        & .social-icons {
            margin-top: 1px;
            font-size: 16px;
        }
    }
}
.comment-form-comment {
    & .comment-notes {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        height: 140px;
        font-size: 14px;
        color: #666;
    }
    & label {
        display: block;
        span {
            color: $theme-color;
        }
    }
}
.comment-form-author, .comment-form-email, .comment-form-url {
    float: left;
    padding: 0 10px;
    width: 33.3333%;
    @media #{$small-mobile} {
        width: 100%;
    }
    @media #{$large-mobile} {
        width: 100%;
    }
}
.comment-input {
    margin: 0 -10px;
    overflow: hidden;
    & label {
        display: block;
    }
    & input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        height: 40px;
        font-size: 14px;
        color: #666;
    }
}
.comment-form-submit {
    & .comment-submit {
        background: $theme-color;
        color: $white;
        border: none;
        padding: 8px 15px;
        margin-top: 20px;
        &:hover {
            background: $black;
        }
    }
}
.blog-wrap-col-3 {
    & .blog-contend {
        & h3 {
            font-size: 18px;
        } 
        .blog-date-categori {
            margin-bottom: 10px;
            & ul {
                & li {
                    display: inline-block;
                    & a {
                        font-size: 14px;
                        margin-right: 10px;
                        font-weight: 400;
                        & i {
                            font-size: 14px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    } 
    & .blog-btn {
        display: inline-block;
        padding: 5px 15px;
    }
}
.pro_review {
    display: flex;
    &.ans {
        margin-bottom: 50px;
        margin-left: 50px;
        margin-top: 50px;
        @media #{$small-mobile}{
          margin-bottom: 30px;
          margin-left: 20px;
          margin-top: 30px;
        }
    }
}
.comment-reply-title {
    font-weight: 600;
    
}
.review_thumb {
    margin-right: 20px;
    min-width: 8%;
    @media #{$large-mobile}{
        min-width:25%;
    }
}
.review_info {
    & h5 {
        font-weight: 600;
    }
}
.rating_send {
    & a {
        font-size: 14px;
        display: block;
        float: right;
        margin-top: -30px;
    }
}

.review_date {
    margin-top: 5px;
}
.review_details {
    & p {
        margin-top: 5px;
    }
}

.blog-sidebar-wrap {
    @media #{$tablet-device}{
        padding-top: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 70px;
    }
    @media #{$small-mobile}{
        padding-top: 60px;
    }
}
.blog-widget {
    
    & .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    & ul {
        & li {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
            display: block;
            &:last-child {
                margin-bottom: 0px;
                padding-bottom: 0px;
                border-bottom: none;
            }
            & a {
                font-size: 14px;
                display: block;
                & span {
                    float: right;
                }
            }
        }
    }
}
.sidebar-tag {
   & > a {
        border: 1px solid #000000;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 10px 0 0;
        padding: 4px 15px;
        &:hover {
            background: $theme-color;
            color: #ffffff;
            border: 1px solid $theme-color;
        }  
    }
}
.widget-blog-inner {
    position: relative;
    & .widget-blog-image {
        position: absolute;
        left: 0;
        top: 5px;
        max-width: 100px;
    }
    & .widget-blog-content {
        position: relative;
        padding-left: 115px;
        min-height: 105px;
        margin-bottom: 20px;
        padding-top: 10px;
        overflow: hidden;
        
        & h6 {
            font-weight: 600;
            font-size: 16px
        }
    }
}